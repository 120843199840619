<template>
  <b-container>
    <ResourceList
      :can-create="false"
      :data="data"
      :is-deleting="isDeleting"
      :is-loading="isLoading"
      :pagination="pagination"
      :search-query="$route.query.query"
      can-delete
      resource="fitting"
      soft-deletes
      title="Fittings"
      @paginate="onPaginate"
      @search="onSearch"
      @delete-many="onDeleteMany">
      <template #utilityContainer>
        <b-card class="mb-4">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Date From:">
                <b-form-datepicker
                  v-model="dateFrom"
                  class="mb-2"
                  @input="onDateChanged">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Date To:">
                <b-form-datepicker
                  v-model="dateTo"
                  class="mb-2"
                  @input="onDateChanged">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <b-form-group label="Payment Status">
                <b-select
                  v-model="paymentStatus"
                  @change="onFilter">
                  <b-select-option :value="null">All</b-select-option>
                  <b-select-option value="Paid">Paid</b-select-option>
                  <b-select-option value="Unpaid">Unpaid</b-select-option>
                </b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <a :disabled="!exportFittingsCSVURL()" :href="exportFittingsCSVURL()" class="btn btn-primary"
                 target="_blank">Export CSV</a>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link
                :to="{ name: 'dash.fittings.view', params: { id: item.order_id }}" class="text-dark">#{{ item.id }}
                {{ item.order.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button
              v-b-popover.bottom="'View more information about this order.'"
              :to="{name: 'dash.fittings.view',params: { id: item.order_id }}"
              class="mr-1 mb-0"
              size="sm"
              variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button
              v-b-popover.bottom="'Edit this order.'" :to="{name: 'dash.orders.update',params: { id: item.id }}"
              class="mr-1 mb-0"
              size="sm"
              variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList";
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import FriendlyDate from "../../components/FriendlyDate";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [currentUser, momentMixin],
  created() {
    if (this.$route.params.paymentStatus) {
      this.paymentStatus = this.$route.params.paymentStatus;
    }

    if (this.$route.params.dateFrom) {
      this.dateFrom = this.$route.params.dateFrom.format("YYYY-MM-DD");
    } else {
      this.dateFrom = this.moment().startOf("year").format("YYYY-MM-DD");
    }

    if (this.$route.params.dateTo) {
      this.dateTo = this.$route.params.dateTo.format("YYYY-MM-DD");
    } else {
      this.dateTo = this.moment().endOf("year").format("YYYY-MM-DD");
    }

    this.fetchMany(this.$route.query);

    this.fetchMany({
      ...this.$route.query,
      startDate: this.dateFrom,
      endDate: this.dateTo,
      paymentStatus: this.paymentStatus
    });
  },
  data: () => ({
    dateFrom: null,
    dateTo: null,
    paymentStatus: null
  }),
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("fittings", [
      "data",
      "isDeleting",
      "isLoading",
      "pagination"
    ])
  },
  methods: {
    ...mapActions("fittings", ["deleteMany", "fetchMany"]),

    onDateChanged() {
      this.fetchMany({
        ...this.$route.query,
        startDate: this.dateFrom,
        endDate: this.dateTo,
        paymentStatus: this.paymentStatus
      });
    },

    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids});
      this.$bvModal.hide(modalId);
      await this.fetchMany(this.$route.query);
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({
          name: this.$route.name, query: {
            ...this.$route.query, page,
            startDate: this.dateFrom,
            endDate: this.dateTo,
            paymentStatus: this.paymentStatus
          }
        }).catch(() => {
        });
        await this.fetchMany({
          ...this.$route.query, page,
          startDate: this.dateFrom,
          endDate: this.dateTo,
          paymentStatus: this.paymentStatus
        }).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {
          name: this.$route.name, query: {
            ...this.$route.query, query,
            startDate: this.dateFrom,
            endDate: this.dateTo,
            paymentStatus: this.paymentStatus
          }
        };
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },


    async onFilter() {
      await this.fetchMany({
        ...this.$route.query,
        startDate: this.dateFrom,
        endDate: this.dateTo,
        paymentStatus: this.paymentStatus
      });
    },

    exportFittingsCSVURL() {
      if (this.dateFrom && this.dateTo) {
        return (
          `/api/export/fittings?start_date=${this.dateFrom}&end_date=${this.dateTo}` +
          (this.paymentStatus != null
            ? `&payment_status=${this.paymentStatus}`
            : "")
        );
      }
      return null;
    }
  }
};
</script>
