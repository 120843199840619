// import pagination from '../../utils/pagination'
import {
  CALENDARS_BATCH_DELETE_FAILURE,
  CALENDARS_BATCH_DELETE_REQUEST,
  CALENDARS_BATCH_DELETE_SUCCESS,
  CALENDARS_FETCH_FAILURE,
  CALENDARS_FETCH_MANY_FAILURE,
  CALENDARS_FETCH_MANY_REQUEST,
  CALENDARS_FETCH_MANY_SUCCESS,
  CALENDARS_FETCH_REQUEST,
  CALENDARS_FETCH_SUCCESS,
  CALENDARS_SAVE_FAILURE,
  CALENDARS_SAVE_REQUEST,
  CALENDARS_SAVE_SUCCESS,
  CLEAR_ERRORS,
  EMPTY_CALENDARS
} from './types'

export default {
  [CALENDARS_BATCH_DELETE_REQUEST](state) {
    state.deleting = true
  },
  [CALENDARS_BATCH_DELETE_SUCCESS](state) {
    state.deleting = false
  },
  [CALENDARS_BATCH_DELETE_FAILURE](state) {
    state.deleting = false
  },
  [CALENDARS_FETCH_MANY_REQUEST](state) {
    state.data = []
    state.loading = true
  },
  [CALENDARS_FETCH_MANY_SUCCESS](state, {data}) {
    state.data = data
    // state.page = pagination(data)
    state.loading = false
  },
  [CALENDARS_FETCH_MANY_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [CALENDARS_FETCH_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [CALENDARS_FETCH_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [CALENDARS_FETCH_FAILURE](state) {
    state.loading = false
  },
  [CALENDARS_SAVE_REQUEST](state) {
    state.data = []
    state.saving = true
  },
  [CALENDARS_SAVE_SUCCESS](state) {
    state.saving = false
  },
  [CALENDARS_SAVE_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_CALENDARS](state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS](state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  }
}
