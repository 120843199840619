<template>
  <router-view/>
</template>

<script>
import calendarModule from '../../store/calendars'
import customerModule from "../../store/customers";

export default {
  beforeCreate() {
    this.$store.registerModule('calendars', calendarModule)
    this.$store.registerModule("customers", customerModule)
  },
  destroyed() {
    this.$store.unregisterModule('calendars')
    this.$store.unregisterModule("customers")
  }
}
</script>
