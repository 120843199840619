export const ORDER_ITEMS_BATCH_DELETE_REQUEST = 'ORDER_ITEMS_BATCH_DELETE_REQUEST'
export const ORDER_ITEMS_BATCH_DELETE_SUCCESS = 'ORDER_ITEMS_BATCH_DELETE_SUCCESS'
export const ORDER_ITEMS_BATCH_DELETE_FAILURE = 'ORDER_ITEMS_BATCH_DELETE_FAILURE'

export const ORDER_ITEMS_FETCH_MANY_REQUEST = 'ORDER_ITEMS_FETCH_MANY_REQUEST'
export const ORDER_ITEMS_FETCH_MANY_SUCCESS = 'ORDER_ITEMS_FETCH_MANY_SUCCESS'
export const ORDER_ITEMS_FETCH_MANY_FAILURE = 'ORDER_ITEMS_FETCH_MANY_FAILURE'

export const ORDER_ITEMS_FETCH_REQUEST = 'ORDER_ITEMS_FETCH_REQUEST'
export const ORDER_ITEMS_FETCH_SUCCESS = 'ORDER_ITEMS_FETCH_SUCCESS'
export const ORDER_ITEMS_FETCH_FAILURE = 'ORDER_ITEMS_FETCH_FAILURE'

export const ORDER_ITEMS_SAVE_REQUEST = 'ORDER_ITEMS_SAVE_REQUEST'
export const ORDER_ITEMS_SAVE_SUCCESS = 'ORDER_ITEMS_SAVE_SUCCESS'
export const ORDER_ITEMS_SAVE_FAILURE = 'ORDER_ITEMS_SAVE_FAILURE'

export const EMPTY_ORDER_ITEMS = 'EMPTY_ORDER_ITEMS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
