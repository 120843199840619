<template>
	<router-view />
</template>

<script>
import customerModule from "../../store/customers"
import orderItemModule from "../../store/order_items"

export default {
	beforeCreate() {
        this.$store.registerModule("customers", customerModule)
		this.$store.registerModule("order_items", orderItemModule)
	},
	destroyed() {
        this.$store.unregisterModule("customers")
		this.$store.unregisterModule("order_items")
	},
}
</script>
