<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="6">
        <h1 class="font-weight-bold text-primary">View Fitting</h1>
      </b-col>
      <b-col cols="6">
        <b-button :to="{ name: 'dash.orders.view', params: { id: order.id } }" class="float-right mb-0"
                  variant="outline-primary">View Order
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">

        <b-row>
          <b-col cols="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'id')" label="Order ID">
              <b-form-input v-model="order.id" :state="validationState(errors.errors, 'id')" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'order_date')" label="Order Date">
              <b-form-datepicker v-model="order.order_date" :state="validationState(errors.errors, 'order_date')"
                                 disabled></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Order Notes -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('order_notes')">
                  <b-icon :icon="collapsed['order_notes'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Order Notes</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_notes']" class="mt-4">
                <!-- Add New Note Field -->
                <b-col v-if="!order.order_notes.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no notes.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="order.order_notes.length" cols="12">
                  <hr/>
                  <b-row v-for="(note, noteInd) in order.order_notes" :key="noteInd">
                    <b-col
                      :invalid-feedback="validationInvalidFeedback(errors.errors, 'order_notes.' + noteInd + '.note')"
                      cols="12">
                      <b-card class="p-3 mt-3" no-body>
                        <p class="mb-0">{{ note.note }}</p>
                        <hr/>
                        <b-row>
                          <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                            <p class="note-created">Left by: {{ note.user ? note.user.name : 'Unknown' }}</p>
                          </b-col>
                          <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                            <p class="note-created text-muted">
                              <FriendlyDate :date="note.created_at"></FriendlyDate>
                            </p>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="mb-4">
          <b-row>
            <b-col class="d-flex flex-row" cols="12" md="6" role="button" @click.prevent="toggleCollapse('customer')">
              <b-icon :icon="collapsed['customer'] ? 'chevron-right' : 'chevron-down'"
                      class="align-self-center cursor-pointer text-muted"
                      font-scale="1.5"></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Customer Details</p>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="!collapsed['customer']" class="mt-4">
            <b-col cols="12">
              <b-form-group label="Customer Name">
                <b-form-input v-model="order.customer.name" class="mb-2" disabled rows="1"></b-form-input>
              </b-form-group>
              <b-form-group label="Customer Email">
                <b-form-input v-model="order.customer.email" class="mb-2" disabled></b-form-input>
              </b-form-group>
              <b-form-group label="Customer Contact Number">
                <b-form-input v-model="order.customer.contact_number" class="mb-2" disabled></b-form-input>
              </b-form-group>
              <b-form-group label="Fitting Address Line 1">
                <b-form-input v-model="order.customer.fitting_address_line_1" class="mb-2" disabled></b-form-input>
              </b-form-group>
              <b-form-group v-show="order.customer.address_line_2" label="Fitting Address Line 2">
                <b-form-input v-model="order.customer.fitting_address_line_2" class="mb-2" disabled></b-form-input>
              </b-form-group>
              <b-form-group v-show="order.customer.fitting_county" label="Fitting Fitting County">
                <b-form-input v-model="order.customer.fitting_county" class="mb-2" disabled></b-form-input>
              </b-form-group>
              <b-form-group v-show="order.customer.fitting_postcode" label="Fitting Fitting Postcode">
                <b-form-input v-model="order.customer.fitting_postcode" class="mb-2" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- Order Items -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="9" role="button"
                       @click.prevent="toggleCollapse('order_items')">
                  <b-icon :icon="collapsed['order_items'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Items</p>
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="!collapsed['order_items']" class="mt-4">
                <b-col v-if="order.order_items.length" cols="12">
                  <b-card>
                    <b-row v-if="!collapsed['order_items']" class="mt-4">
                      <b-col v-if="!order.order_items.length" cols="12">
                        <b-col class="text-center" cols="12">
                          <b-card class="py-3 text-center">
                            <p class="mb-4">
                              <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                            </p>
                            <p class="font-weight-normal h5 mb-0 text-dark">There are no items.</p>
                          </b-card>
                        </b-col>
                      </b-col>
                      <b-col v-if="order.order_items.length" cols="12">
                        <b-table-simple hover responsive striped>
                          <b-thead>
                            <b-tr>
                              <b-th>Product</b-th>
                              <b-th>Quantity</b-th>
                              <b-th>Fitting Date</b-th>
                              <b-th>Fitting Charge</b-th>
                              <b-th>Cost</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(item, itemInd) in order.order_items" v-if="!item.deleted_at" :key="itemInd">
                              <td>
                                <div class="d-flex flex-fill flex-column">
                                  <p class="mb-0">{{ item.name }}</p>
                                </div>
                              </td>
                              <td>
                                {{ item.quantity }}
                                <div v-if="item.length && item.width === 0" class="text-muted"
                                     style="font-size: 12px !important; text-align: left !important">
                                  Packs: {{ item.quantity }}
                                </div>
                                <div v-else class="text-muted"
                                     style="font-size: 12px !important; text-align: left !important">
                                  L: {{ item.length }} x W: {{ item.width }}
                                </div>
                              </td>
                              <td v-if="item.fitting_date">{{ moment(item.fitting_date).format("Do MMM YYYY") }}</td>
                              <td v-else>No Date Selected</td>
                              <td v-if="item.fitting_charge">{{ item.fitting_charge }}</td>
                              <td v-else>No Fitting Charge</td>
                              <td v-if="item.fitting_charge === 'Invoice Fitting Charge'">
                                {{ item.fitting_cost_inc_vat | currencyFormat }}
                              </td>
                              <td v-else-if="item.fitting_charge === 'Direct Fitting Charge'">
                                {{ item.fitting_cost_exc_vat | currencyFormat }}
                              </td>
                              <td v-else>No Fitting Cost</td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Uploads -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                       @click.prevent="toggleCollapse('order_uploads')">
                  <b-icon :icon="collapsed['order_uploads'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Uploads</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                <b-col v-if="!order.order_uploads.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no uploaded files.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="order.order_uploads.length" cols="12">
                  <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                    <b-col v-if="order.order_items.length" cols="12">
                      <b-table-simple hover responsive striped>
                        <b-thead class="text-center">
                          <b-tr>
                            <b-th>Document Title</b-th>
                            <b-th>Uploaded Date</b-th>
                            <b-th>View</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody class="text-center">
                          <b-tr v-for="(item, ind) in order.order_uploads" :key="ind">
                            <td>{{ item.document.filename }}</td>
                            <td>{{ moment(item.created_at).format("Do MMM YYYY") }}</td>
                            <td>
                              <b-button v-b-popover.bottom="'View this document.'" class="mr-1 mb-0" size="sm"
                                        variant="secondary">
                                <b-icon icon="eye"/>
                                <a :href="item.document.url" class="text-white" target="_blank"> View</a></b-button>
                            </td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"
import momentMixin from "../../mixins/momentMixin"

export default {
  mixins: [validation, momentMixin],
  components: {
    FriendlyDate
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters("orders", ["errors", "isLoading", "isSaving", "single"])
  },
  data() {
    return {
      collapsed: {
        customer: !this.$route.params.id,
        order_items: true,
        order_notes: true,
        order_uploads: true
      },

      order: {
        user_id: null,
        user: {},
        customer_id: null,
        customer: {},
        payment_id: null,
        payment: [],
        order_items_id: null,
        order_items: [],
        fitting_date: null,
        supplier: null,
        order_date: null,
        order_notes: [],
        order_uploads: []
      }
    }
  },
  methods: {
    ...mapActions("orders", ["fetch", "save"]),

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name]
    }
  },
  watch: {
    single() {
      this.order = this.single
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>
