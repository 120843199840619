<template>
  <b-container>
    <ResourceList :can-create="false" :can-select-many="false" :data="data" :has-buttons="false"
                  :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query" resource="supply order notification"
                  resource-plural="notifications"
                  title="Order Note Notifications" @paginate="onPaginate" @search="onSearch">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div v-if="item.completed == 0" class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill text-primary">
              <router-link :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="text-dark">#{{ item.id }}
                {{ item.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">
              <b-button v-b-popover.hover.bottom="'View more information about this order.'"
                        :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="mr-1 mb-0"
                        size="sm"
                        variant="primary">
                <b-icon icon="cart"/>
                View Order
              </b-button>
            </p>
          </div>
          <div>
            <small class="text-muted">
              <FriendlyDate :date="item.created_at"/>
            </small>
          </div>

          <div>
            <hr>
          </div>

          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <b-card class="w-100">
              <h6>Order Note Notifications:</h6>
              <ul class="list-group mt-3">
                <li v-for="(orderNote, orderNoteInd) in item.order_note_notifications"
                    :key="`notification-${item.id}-${orderNoteInd}`"
                    class="list-group-item">
                  <p class="mb-0">{{ orderNote.note.note }}</p>
                  <small class="text-muted">Created recently by {{ orderNote.user.name }}</small>
                  <hr>
                  <b-button v-if="hasAnyPermission(['dismiss-notifications'])"
                            v-b-popover.hover.bottom="'Dismiss this notification.'" class="mr-1 mb-0"
                            size="sm"
                            variant="secondary"
                            @click="doDismissNotification(orderNote.id)">
                    <fa-icon icon="bell-slash"/>
                    Dismiss Notification
                  </b-button>
                </li>
              </ul>
            </b-card>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchNoteNotifications(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("orders", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("orders", ["batchDelete", "fetchNoteNotifications", "dismissNoteNotification", "getNoteNotificationCount"]),
    async onDeleteMany({ids, modalId}) {
      await this.batchDelete({ids})
      this.$bvModal.hide(modalId)
      await this.fetchNoteNotifications(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.push({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        })
        await this.fetchNoteNotifications({...this.$route.query, page}).catch(() => {
        })
      }
    },
    async onSearch(query) {
      if (this.$route.query.query !== query) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, query}}).catch(() => {
        })
        await this.fetchNoteNotifications({...this.$route.query, query}).catch(() => {
        })
      }
    },

    doDismissNotification(notificationId) {
      this.dismissNoteNotification(notificationId)
        .then(response => {
          this.doPaginate()
          this.getNoteNotificationCount()
        })
    },
  }
}
</script>
<style>
</style>
