import pagination from '../../utils/pagination'
import {
  ORDER_ITEMS_BATCH_DELETE_REQUEST, ORDER_ITEMS_BATCH_DELETE_SUCCESS, ORDER_ITEMS_BATCH_DELETE_FAILURE,
  ORDER_ITEMS_FETCH_MANY_REQUEST, ORDER_ITEMS_FETCH_MANY_SUCCESS, ORDER_ITEMS_FETCH_MANY_FAILURE,
  ORDER_ITEMS_FETCH_REQUEST, ORDER_ITEMS_FETCH_SUCCESS, ORDER_ITEMS_FETCH_FAILURE,
  ORDER_ITEMS_SAVE_REQUEST, ORDER_ITEMS_SAVE_SUCCESS, ORDER_ITEMS_SAVE_FAILURE,
  EMPTY_ORDER_ITEMS,
  CLEAR_ERRORS
} from './types'

export default {
  [ORDER_ITEMS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [ORDER_ITEMS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [ORDER_ITEMS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [ORDER_ITEMS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [ORDER_ITEMS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [ORDER_ITEMS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [ORDER_ITEMS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [ORDER_ITEMS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [ORDER_ITEMS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [ORDER_ITEMS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [ORDER_ITEMS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [ORDER_ITEMS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_ORDER_ITEMS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
