<template>
  <b-container>
    <ResourceList :can-create="false" :data="Object.values(data)" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query"
                  resource="fitting" title="Fitting Notifications" @paginate="onPaginate" @search="onSearch">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link v-if="item.order_item"
                           :to="{ name: 'dash.fittings.view', params: { id: item.order_item.order_id } }"
                           class="text-dark">
                {{ item.order_item && item.order_item.order ? '#' + item.order_item.order.id + ' - ' + item.order_item.order.customer.name :
                '#' + item.id + ' - ' + item.title }}
              </router-link>

              <a v-else class="text-dark">
                {{ item.title }}
              </a>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-none d-md-flex flex-row">

            <b-button v-if="item.order_item && item.order_item.order"
                      v-b-popover="'View more information about this order.'"
                      :to="{ name: 'dash.fittings.view', params: { id: item.order_item.order_id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-if="hasAnyPermission(['dismiss-notifications'])"
                      v-b-popover.bottom="'Dismiss this notification.'" class="mr-1 mb-0"
                      size="sm"
                      variant="secondary"
                      @click="doDismissNotification(item.id)">
              <fa-icon icon="bell-slash"/>
              Dismiss Notification
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"
import momentMixin from "../../mixins/momentMixin"

export default {
  mixins: [currentUser, momentMixin],
  created() {
    this.fetchMany(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("fittings", ["data", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("fittings", {
      fetchMany: "fetchFittingNotifications",
      getNotificationCount: "getNotificationCount",
      dismissNotification: "dismissNotification",
    }),

    async doPaginate() {
      await this.fetchMany({...this.$route.query}).catch(() => {
      });
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },


    doDismissNotification(notificationId) {
      this.dismissNotification(notificationId)
        .then(() => {
          this.doPaginate()
          this.getNotificationCount()
        })
    },
    hasAnyPermission(permissions) {
      let hasAnyPermission = false
      if (Array.isArray(permissions)) {
        permissions.forEach(function (permission) {
          if (this.userPermissions.includes(permission)) hasAnyPermission = true
        }, this)
      }
      return hasAnyPermission
    },
  }
}
</script>
