import {
  CALENDARS_BATCH_DELETE_FAILURE,
  CALENDARS_BATCH_DELETE_REQUEST,
  CALENDARS_BATCH_DELETE_SUCCESS,
  CALENDARS_FETCH_FAILURE,
  CALENDARS_FETCH_MANY_FAILURE,
  CALENDARS_FETCH_MANY_REQUEST,
  CALENDARS_FETCH_MANY_SUCCESS,
  CALENDARS_FETCH_REQUEST,
  CALENDARS_FETCH_SUCCESS,
  CALENDARS_SAVE_FAILURE,
  CALENDARS_SAVE_REQUEST,
  CALENDARS_SAVE_SUCCESS,
  CLEAR_ERRORS,
  EMPTY_CALENDARS
} from './types'

export default {
  deleteMany({commit}, {ids = []}) {
    commit(CALENDARS_BATCH_DELETE_REQUEST)
    return window.axios
      .post('/calendars', {_method: 'DELETE', ids})
      .then(response => {
        commit(CALENDARS_BATCH_DELETE_SUCCESS, response)
        return response
      })
      .catch(error => {
        commit(CALENDARS_BATCH_DELETE_FAILURE, error)
        throw error
      })
  },
  fetchMany({commit}) {
    commit(CALENDARS_FETCH_MANY_REQUEST)
    return window.axios.get('/calendars').then(response => {
      commit(CALENDARS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CALENDARS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch({commit}, id) {
    commit(CALENDARS_FETCH_REQUEST)
    return window.axios.get(`/calendars/${id}`).then(response => {
      commit(CALENDARS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CALENDARS_FETCH_FAILURE, error)
      throw error
    })
  },
  save({commit, dispatch}, event) {
    const path = event.id ? `/calendars/${event.id}` : '/calendars'
    const method = event.id ? 'put' : 'post'

    commit(CALENDARS_SAVE_REQUEST)
    return window.axios[method](path, event).then(response => {
      commit(CALENDARS_SAVE_SUCCESS, response)
      dispatch('fetchMany')
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CALENDARS_SAVE_FAILURE, {
          validationErrors: error.response.data
        })
      } else {
        commit(CALENDARS_SAVE_FAILURE, {error})
      }
      throw error
    })
  },
  empty({commit}) {
    commit(EMPTY_CALENDARS);
  },
  clearErrors({commit}) {
    commit(CLEAR_ERRORS)
  }
}
