export const CALENDARS_BATCH_DELETE_REQUEST = 'CALENDARS_BATCH_DELETE_REQUEST'
export const CALENDARS_BATCH_DELETE_SUCCESS = 'CALENDARS_BATCH_DELETE_SUCCESS'
export const CALENDARS_BATCH_DELETE_FAILURE = 'CALENDARS_BATCH_DELETE_FAILURE'

export const CALENDARS_FETCH_MANY_REQUEST = 'CALENDARS_FETCH_MANY_REQUEST'
export const CALENDARS_FETCH_MANY_SUCCESS = 'CALENDARS_FETCH_MANY_SUCCESS'
export const CALENDARS_FETCH_MANY_FAILURE = 'CALENDARS_FETCH_MANY_FAILURE'

export const CALENDARS_FETCH_REQUEST = 'CALENDARS_FETCH_REQUEST'
export const CALENDARS_FETCH_SUCCESS = 'CALENDARS_FETCH_SUCCESS'
export const CALENDARS_FETCH_FAILURE = 'CALENDARS_FETCH_FAILURE'

export const CALENDARS_SAVE_REQUEST = 'CALENDARS_SAVE_REQUEST'
export const CALENDARS_SAVE_SUCCESS = 'CALENDARS_SAVE_SUCCESS'
export const CALENDARS_SAVE_FAILURE = 'CALENDARS_SAVE_FAILURE'

export const EMPTY_CALENDARS = 'EMPTY_CALENDARS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
